import React from 'react'
import _noop from 'lodash/noop'
import _clamp from 'lodash/clamp'
import _round from 'lodash/round'
import { bool, string, func, shape, number, arrayOf } from 'prop-types'
import { Swipeable } from 'react-swipeable'
import ReactAnimationFrame from 'react-animation-frame'

import Showcase360Styled from './styles'
import AssetLoader from '../AssetLoader'
import VehicleShowcaseActivate from '../VehicleShowcaseActivate'
import Navigation from '../Navigation'

class VehicleShowcase360 extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDragging: false,
      prefetch: [],
      assets: [],
      screen: {
        width: 1,
        height: 1,
        ratio: 1,
      },
      context: null,
      isLoaded: false,
      isLoading: false,
      hasDragged: false,
    }

    this._onActivate = this._onActivate.bind(this)
    this._onAssetsLoaded = this._onAssetsLoaded.bind(this)
    this._addEventListeners = this._addEventListeners.bind(this)
    this._removeEventListeners = this._removeEventListeners.bind(this)
    this._handleMouseUp = this._handleMouseUp.bind(this)
    this._handleMouseMove = this._handleMouseMove.bind(this)
    this._getValue = this._getValue.bind(this)
    this._handleOnChange = this._handleOnChange.bind(this)
    this._handleWindowResize = this._handleWindowResize.bind(this)
    this._onSwiping = this._onSwiping.bind(this)
    this._onSwiped = this._onSwiped.bind(this)
    this._startAutoPlay = this._startAutoPlay.bind(this)
    this._stopAutoPlay = this._stopAutoPlay.bind(this)
    this._setCanvasRatio = this._setCanvasRatio.bind(this)
    this._setDefaultMousePosition = this._setDefaultMousePosition.bind(this)
    this.value = 0
    this._historic = { frame: -1, mouse: { x: 0, y: 0 } }
    this._target = 0
    this._zoom = 1
    this._autoPlayTimer = null
    this._mouse = { x: 0, y: 0, current: { x: 0, y: 0 } }
  }

  componentDidMount() {
    const {
      endAnimation,
      load,
    } = this.props
    const {
      screen,
    } = this.state
    const context = this.canvas.getContext('2d')

    this.setState({ context })

    endAnimation()

    this._handleWindowResize()

    context.scale(screen.ratio, screen.ratio)

    if (load) this._onActivate()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this._handleWindowResize)
    }

    const showcaseClientRect = this.showcase.getBoundingClientRect()
    this._setCanvasRatio(showcaseClientRect.width)
    this._setDefaultMousePosition()
  }

  componentWillUnmount() {
    const {
      endAnimation,
    } = this.props

    this._removeEventListeners()
    endAnimation()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const showcaseClientRect = this.showcase.getBoundingClientRect()
    if (nextProps.position.width) {
      this._setCanvasRatio(nextProps.position.width)
    } else {
      this._setCanvasRatio(showcaseClientRect.width)
    }
  }

  _setDefaultMousePosition() {
    const _midpoint = {
      x: this.canvas.width * 0.5,
      y: this.canvas.height * 0.5,
    }
    this._mouse = {
      x: _midpoint.x,
      y: _midpoint.y,
      current: { x: _midpoint.x, y: _midpoint.y },
    }
  }

  _setCanvasRatio(_width) {
    const {
      screen,
    } = this.state

    const aspectRatio = 0.6667
    const canvasWidth = _width
    const canvasHeight = _width * aspectRatio

    if (
      canvasHeight !== screen.height
      || canvasWidth !== screen.width
    ) {
      this.setState({ canvasWidth, canvasHeight })
    }
  }

  _addEventListeners() {
    if (typeof window !== 'undefined' && this.canvas) {
      document.addEventListener('mouseup', this._handleMouseUp)
      this.canvas.addEventListener('mousemove', this._handleMouseMove)
    }
  }

  _removeEventListeners() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('mouseup', this._handleMouseUp)
      window.removeEventListener('resize', this._handleWindowResize)
      this.canvas.removeEventListener('mousemove', this._handleMouseMove)
    }
  }

  _handleWindowResize() {
    if (typeof window !== 'undefined') {
      this.setState({
        screen: {
          width: window.innerWidth,
          height: window.innerHeight,
          ratio: window.devicePixelRatio || 1,
        },
      })
      const showcaseClientRect = this.showcase.getBoundingClientRect()

      this._setCanvasRatio(showcaseClientRect.width)
    }
  }

  _startAutoPlay() {
    const {
      assets,
    } = this.props

    const _framerate = 100
    const _delayBeforeStart = 100

    setTimeout(() => {
      this._autoPlayTimer = setInterval(() => {
        this._handleOnChange(this._target + 1)

        if (this._target >= assets.length) {
          this._stopAutoPlay()
        }
      }, _framerate)
    }, _delayBeforeStart)
  }

  _stopAutoPlay() {
    if (this._autoPlayTimer) {
      clearInterval(this._autoPlayTimer)

      this._historic.frame = this._target + 1
      this._autoPlayTimer = null
    }
  }

  _onAssetsLoaded(_loadedImages) {
    const {
      autoplay,
      startAnimation,
    } = this.props

    this.setState({ prefetch: _loadedImages, isLoaded: true })
    this._addEventListeners()

    if (autoplay) this._startAutoPlay()

    startAnimation()
  }

  _getOffset(_el) {
    const elementClientRect = _el.getBoundingClientRect()

    return {
      left: elementClientRect.left + window.scrollX,
      top: elementClientRect.top + window.scrollY,
    }
  }

  // MARK: Canvas
  onAnimationFrame() {
    const {
      prefetch,
      context,
    } = this.state

    const img = prefetch[this.value]

    if (!img) return

    const clampedX = _clamp(this._mouse.x, 0, context.canvas.width)
    const clampedY = _clamp(this._mouse.y, 0, context.canvas.height)

    // draw the 360 image
    context.drawImage(
      img,
      0 - clampedX * (this._zoom - 1),
      0 - clampedY * (this._zoom - 1),
      context.canvas.width * this._zoom,
      context.canvas.height * this._zoom
    )
    this._mouse.y = clampedY
    this._mouse.x = clampedX

    if (this.value !== this._target) {
      this.value = this._target
    }
  }

  // MARK: Mouse Events
  _onActivate() {
    const {
      assets,
      onActive,
    } = this.props

    this.setState({ assets, isLoading: true })

    onActive()
  }

  _handleMouseUp() {
    this.setState({
      isDragging: false,
    })
  }

  _handleMouseMove(_e) {
    if (this._zoom <= 1) {
      this._mouse = {
        x: _e.clientX,
        y: _e.clientY,
      }
      this._historic.mouse = {
        x: _e.clientX,
        y: _e.clientY,
      }
    }

    this._mouse.current = {
      x: _e.clientX,
      y: _e.clientY,
    }
  }

  // MARK: Touch Events

  _onSwiping(_e) {
    const {
      isLoaded,
    } = this.state
    const { deltaX, deltaY } = _e

    if (isLoaded) {
      this._stopAutoPlay()
      this.setState({ isDragging: true, hasDragged: true })

      if (this._zoom <= 1) {
        const frameValue = this._getValue(deltaX)
        this._handleOnChange(frameValue)
      } else {
        this._mouse = {
          x: this._historic.mouse.x + deltaX,
          y: this._historic.mouse.y + deltaY,
        }
      }

      this._mouse.current = {
        x: _e.clientX,
        y: _e.clientY,
      }
    }
  }

  _onSwiped() {
    this._historic.frame = this.value
    this._historic.mouse = {
      x: this._mouse.x,
      y: this._mouse.y,
    }
  }

  // MARK: Frame control
  _getValue(_delta, _precision = 0) {
    const {
      reverse,
      assets,
    } = this.props

    const {
      screen,
    } = this.state

    _delta = !reverse ? _delta : _delta * -1
    let _frames = assets.length
    // what is the total movement we can expect
    const _scaleOfMovement = _clamp(screen.width, 100, 550) * 1.8
    const _percentageOfScale = Math.abs(_delta) / _scaleOfMovement
    // should we move forwards or backwards
    _frames = _delta >= 0 ? _frames : _frames * -1
    // then, work out the percentage of how far we're through our total frame count
    let _value = _frames * _percentageOfScale
    // we'll want integers to match to a frame index
    _value = _round(_value, _precision)
    return _value - 1
  }

  _handleOnChange(_newFrameValue) {
    const {
      assets,
      startAnimation,
    } = this.props

    const numberOfAssets = assets.length - 1
    // a framevalue < 0 will appear to switch rotation direction. Let's give it some padding some we never approach 0
    let _relativeFrameValue = Math.abs(
      _newFrameValue + (this._historic.frame + numberOfAssets) * -1
    )

    _relativeFrameValue %= numberOfAssets
    if (this._historic.frame !== _relativeFrameValue) {
      this._target = _relativeFrameValue

      startAnimation()
    }
  }

  render() {
    const {
      load,
      poster,
      assets,
      slidesLength,
      activeIndex,
      onLeftClick,
      onRightClick,
    } = this.props
    const {
      isDragging,
      isLoading,
      isLoaded,
      prefetch,
      screen,
      canvasWidth,
      canvasHeight,
      assets: stateAssets,
      hasDragged,
    } = this.state
    const isToAutoLoad = load

    return (
      <>
        <Showcase360Styled
          isDragging={isDragging}
          isLoading={isLoading}
          isLoaded={isLoaded}
          onMouseDown={this._stopAutoPlay}
          poster={poster}
          className={prefetch.length === 0 ? 'poster' : ''}
          windowRatio={screen.height / screen.width}
        >
          <div
            ref={_c => {
              this.showcase = _c
            }}
          >
            <Swipeable
              onSwiping={this._onSwiping}
              onSwiped={this._onSwiped}
              trackMouse
            >
              <canvas
                ref={_c => {
                  this.canvas = _c
                }}
                width={(canvasWidth * screen.ratio) || 0}
                height={(canvasHeight * screen.ratio) || 0}
              />
            </Swipeable>
          </div>
          {(assets.length > 0 && !hasDragged) && (
            <VehicleShowcaseActivate
              isLoaded={isLoaded && hasDragged}
              loading={isToAutoLoad && hasDragged}
            >
              360°
            </VehicleShowcaseActivate>
          )}
        </Showcase360Styled>
        <AssetLoader
          assets={stateAssets}
          onLoaded={this._onAssetsLoaded}
        />
        <Navigation
          slidesLength={slidesLength}
          activeIndex={activeIndex}
          onLeftClick={onLeftClick}
          onRightClick={onRightClick}
        />
      </>
    )
  }
}

VehicleShowcase360.defaultProps = {
  reverse: false,
  autoplay: false,
  poster: '',
  onActive: () => {},
  load: false,
  position: { x: 0, y: 0 },
  assets: [],
  endAnimation: _noop,
  startAnimation: _noop,
  slidesLength: 0,
  activeIndex: 0,
  onLeftClick: _noop,
  onRightClick: _noop,
}

VehicleShowcase360.propTypes = {
  assets: arrayOf(string),
  reverse: bool,
  autoplay: bool,
  poster: string,
  onActive: func,
  endAnimation: func,
  startAnimation: func,
  load: bool,
  position: shape({
    x: number,
    y: number,
  }),
  slidesLength: number,
  activeIndex: number,
  onLeftClick: func,
  onRightClick: func,
}

export default ReactAnimationFrame(VehicleShowcase360)
