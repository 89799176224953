import React from 'react'
import { bool, func, node } from 'prop-types'
import _noop from 'lodash/noop'

import {
  RoundelStyled,
  CenterStyled,
  ActivateArrowsStyled,
  SpinnerStyled,
} from './styles'


class VehicleShowcaseActivate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
    }

    this._handleOnClick = this._handleOnClick.bind(this)
  }

  componentDidMount() {
    const { loading } = this.props

    if (loading) this._handleOnClick()
  }

  _handleOnClick() {
    const { handleClickCallBack } = this.props

    if (!handleClickCallBack) return

    this.setState({ isLoading: true })
    handleClickCallBack()
  }

  render() {
    const { isLoading } = this.state
    const { isLoaded, children, handleClickCallBack } = this.props
    const isLoadedClassnames = isLoaded ? 'remove' : ''
    const classname = isLoading
      ? `loading ${isLoadedClassnames}`
      : isLoadedClassnames

    return (
      <RoundelStyled
        className={classname}
        onClick={this._handleOnClick}
        isLoading={isLoading}
        isClickable={handleClickCallBack !== _noop}
      >
        <CenterStyled>
          <SpinnerStyled className="spinner" />
          <span>
            <div className="arrow">
              <ActivateArrowsStyled />
            </div>
            <span className="content">{children}</span>
          </span>
        </CenterStyled>
      </RoundelStyled>
    )
  }
}

VehicleShowcaseActivate.defaultProps = {
  loading: false,
  isLoaded: true,
  handleClickCallBack: _noop,
  children: null,
}

VehicleShowcaseActivate.propTypes = {
  handleClickCallBack: func,
  isLoaded: bool,
  loading: bool,
  children: node,
}

export default VehicleShowcaseActivate
