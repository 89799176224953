/* eslint-disable import/prefer-default-export */
export const tryGetImage = src => new Promise((resolve, reject) => {
  const img = document.createElement('img')

  img.onload = () => {
    resolve()
    img.remove()
  }

  img.onerror = () => {
    reject()
    img.remove()
  }

  img.src = src
})

export const isDevelopment = process.env.NODE_ENV === 'development'
