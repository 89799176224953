import React from 'react'
import { func, number } from 'prop-types'
import _noop from 'lodash/noop'

import ButtonNavigationStyled, { directions } from './styles'
import ArrowLeftIcon from '../Icons/ArrowLeft'
import ArrowRightIcon from '../Icons/ArrowRight'
import { Typo6 } from '../Typography'

const Navigation = ({
  onLeftClick,
  onRightClick,
  slidesLength,
  activeIndex,
}) => {
  const handleClick = clickCallback => () => {
    clickCallback && clickCallback()
  }

  return (
    <>
      {activeIndex
        ? (
          <ButtonNavigationStyled
            direction={directions.left}
            onClick={handleClick(onLeftClick)}
          >
            <ArrowLeftIcon />
          </ButtonNavigationStyled>
        ) : null }
      <ButtonNavigationStyled
        direction={directions.right}
        onClick={handleClick(onRightClick)}
        disabled={activeIndex === slidesLength - 1}
      >
        <ArrowRightIcon />
        <Typo6>{`${activeIndex + 1}/${slidesLength}`}</Typo6>
      </ButtonNavigationStyled>
    </>
  )
}

Navigation.defaultProps = {
  onLeftClick: _noop,
  onRightClick: _noop,
  slidesLength: 0,
  activeIndex: 0,
}

Navigation.propTypes = {
  onLeftClick: func,
  onRightClick: func,
  slidesLength: number,
  activeIndex: number,
}

export {
  ButtonNavigationStyled as NavigationButton,
  Navigation as default,
}
