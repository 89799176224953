import styled from 'styled-components'

const LoaderStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  transition: transform 0.3s ${({ theme }) => theme.ease.ease_out_expo};
  transform: translateY(100%);
  z-index: 500;

  &.show {
    transition: transform 0.5s ${({ theme }) => theme.ease.ease_out_expo};
    transform: translateY(0);
  }
  
  &.hide {
    transition: transform 1s ${({ theme }) => theme.ease.ease_out_circ} 1s;
    transform: translateY(100%);
  }
`

const ProgressStyled = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.gray};
`

const ProgressBarStyled = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 5px;
  z-index: 4;
  transform-origin: 0 0;
  transition: transform 1s ${({ theme }) => theme.ease.ease_out_circ};
  transform: scaleX(${props => props.percentage}) translateZ(0);
  background-color: ${({ theme }) => theme.colors.blue};
`

export {
  LoaderStyled,
  ProgressStyled,
  ProgressBarStyled,
}
