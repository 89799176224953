import styled from 'styled-components'
import { Image } from 'rebass'

import media from '../../common/MediaQueries'

const ImageGallerySliderStyled = styled.div``

const ImageGalleryMediaStyled = styled(Image)`
  margin-bottom: 0;

  @media (${media.phone}) {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media (${media.tablet}) {
    width: ${props => (props.windowRatio > 0.6667
    ? `${(props.windowRatio / 0.6667) * 100}%`
    : '100%')};
    max-width: none;
  }
`

const ImageGalleryMediaWrapperStyled = styled.div``

export {
  ImageGallerySliderStyled,
  ImageGalleryMediaStyled,
  ImageGalleryMediaWrapperStyled,
}
