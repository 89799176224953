import React from 'react'
import styled, { css } from 'styled-components'

const width = '25'
const height = '25'
const viewBox = '0 0 25 25'

const getDimensions = () => ({
  height,
  width,
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
`

const defaultProps = {
  children: [
    <path
      d="M0 10.747h18.685l-8.582-8.582L12.283 0l12.282 12.283-12.282 12.282-2.165-2.164 8.567-8.583H0v-3.07z"
      key="key-arrow-right"
    />,
  ],
  viewBox,
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ArrowRight',
})
