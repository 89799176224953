import styled from 'styled-components'
import { rgba } from 'polished'

const PanoramaWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div::before,
  & > div::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
  }

  & > div::after {
    opacity: 0.3;
    z-index: 3;

    background: ${({ theme }) => rgba(theme.colors.blueGray, 0.2)}
      url(${props => props.poster}) no-repeat center;
    background-size: cover;
    transition: opacity 1.5s 
    ${({ theme }) => theme.ease.ease_out_circ} ${props => (props.isLoading ? '0s' : '0.9s')};
    opacity: ${props => (props.isLoading ? 0.3 : 0)};
  }

  & > div::before {
    opacity: 1;
    z-index: 2;
    background-color: ${({ theme }) => theme.colors.blueGray};
    transition: opacity 1.5s 
    ${({ theme }) => theme.ease.ease_out_circ} ${props => (props.isLoading ? '0s' : '0.9s')};
    opacity: ${props => (props.isLoading ? 1 : 0)};
  }
`

export default PanoramaWrapperStyled
