import React from 'react'

import {
  VehicleShowcaseFrameStyled,
  VehicleShowcaseFramePlaceholderStyled,
  VehicleShowcaseFrameContentStyled,
} from './styles'
import VehicleShowcase from '../VehicleShowcase'

const VehicleShowcaseFrame = props => (
  <VehicleShowcaseFrameStyled>
    <VehicleShowcaseFramePlaceholderStyled />
    <VehicleShowcaseFrameContentStyled>
      <VehicleShowcase {...props} />
    </VehicleShowcaseFrameContentStyled>
  </VehicleShowcaseFrameStyled>
)

export default VehicleShowcaseFrame
