import React from 'react'
import styled, { css } from 'styled-components'

const width = '48'
const height = '48'
const viewBox = '0 0 48 48'

const getDimensions = () => ({
  height,
  width,
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
`

const defaultProps = {
  children: [
    <path
      d="M13.403 11.832a15.883 15.883 0 0 1 9.11-3.725V.292a23.827 23.827 0 0 0-14.708 6.02l5.598 5.52zM7.925 22.493a15.436 15.436 0 0 1 3.778-8.984l-5.598-5.52C2.545 11.87.283 16.929 0 22.493h7.925zM11.703 33.848a15.434 15.434 0 0 1-3.778-8.984H0c.283 5.565 2.545 10.623 6.105 14.505l5.598-5.52zM22.514 39.25a15.884 15.884 0 0 1-9.111-3.725l-5.598 5.52a23.826 23.826 0 0 0 14.709 6.02V39.25zM34.03 35.525a15.884 15.884 0 0 1-9.112 3.725v7.815a23.827 23.827 0 0 0 14.71-6.02l-5.599-5.52zM39.507 24.864a15.436 15.436 0 0 1-3.778 8.984l5.598 5.52c3.56-3.88 5.822-8.94 6.105-14.504h-7.925zM35.73 13.509a15.436 15.436 0 0 1 3.777 8.984h7.925C47.15 16.93 44.887 11.87 41.327 7.99l-5.598 5.52zM24.918.292v7.815a15.886 15.886 0 0 1 9.111 3.725l5.598-5.52A23.826 23.826 0 0 0 24.918.292" 
      opacity="0.3"
      key="key-loading-spinner"
    />,
  ],
  viewBox,
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'LoadingSpinner',
})
