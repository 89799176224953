import React from 'react'
import _noop from 'lodash/noop'
import { string, func, number } from 'prop-types'

import PanoramaWrapperStyled from './styles'
import VehicleShowcaseActivate from '../VehicleShowcaseActivate'
import VehicleShowcasePanorama from '../VehicleShowcasePanorama'
import Navigation from '../Navigation'

class VehicleShowcasePanoramaWrapper extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      ready: false,
    }

    this._handleOnReadyCallback = this._handleOnReadyCallback.bind(this)
  }

  _handleOnReadyCallback() {
    this.setState({ ready: true })
  }

  render() {
    const {
      poster,
      slidesLength,
      activeIndex,
      onLeftClick,
      onRightClick,
    } = this.props
    const { ready } = this.state

    return (
      <>
        <PanoramaWrapperStyled
          width={12 / 12}
          poster={poster}
          isLoading={!ready}
        >
          <VehicleShowcasePanorama
            {...this.props}
            onReadyCallback={this._handleOnReadyCallback}
          />
          <VehicleShowcaseActivate loading isLoaded={ready}>
            360°
          </VehicleShowcaseActivate>
        </PanoramaWrapperStyled>
        <Navigation
          slidesLength={slidesLength}
          activeIndex={activeIndex}
          onLeftClick={onLeftClick}
          onRightClick={onRightClick}
        />
      </>
    )
  }
}

VehicleShowcasePanoramaWrapper.defaultProps = {
  poster: '',
  renderNavigation: _noop,
  slidesLength: 0,
  activeIndex: 0,
  onLeftClick: _noop,
  onRightClick: _noop,
}

VehicleShowcasePanoramaWrapper.propTypes = {
  poster: string,
  renderNavigation: func,
  slidesLength: number,
  activeIndex: number,
  onLeftClick: func,
  onRightClick: func,
}

export default VehicleShowcasePanoramaWrapper
