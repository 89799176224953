import styled from 'styled-components'

const PanoramaStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  & #krpanoSWFObject {
    transition: opacity 1s ${props => props.theme.ease.ease_out_circ} 0.9s;
    opacity: 1;
  }
  
  &.loading #krpanoSWFObject {
    opacity: 0;
  }
`

export default PanoramaStyled
