import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'
import { transition, icon, navigationGradient } from '../../common/Mixins'

export const directions = {
  left: 'left',
  right: 'right',
}

const ButtonNavigationStyled = styled.button`
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 4px);
  height: ${({ theme }) => theme.sizes.buttonNavigationPhoneSize};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => rgba(theme.colors.gray, 0.4)};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
  ${transition}
  ${navigationGradient}

  &:hover {
    background: ${({ theme }) => rgba(theme.colors.gray, 0.5)};
  }
  
  svg {
    ${icon}
    font-size: 16px;
  }
  
  svg + p {
    margin-bottom: -${({ theme }) => theme.gutter.gutter10};
  }
  
  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  @media (${media.phone}) {
    width: calc(50% - 8px);
    height: ${({ theme }) => theme.sizes.buttonNavigationTabletSize};
  }

  @media (${media.tablet}) {
    top: 50%;
    transform: translateY(-50%);
    width:  ${({ theme }) => theme.sizes.buttonNavigationSize};
    height: ${({ theme }) => theme.sizes.buttonNavigationSize};

    svg {
      font-size: 20px;
    }

    &[disabled] {
      opacity: .8;
    }
  }

  ${({ direction }) => direction === directions.left && css`
    left: 0;
  `}

  ${({ direction }) => direction === directions.right && css`
    right: 0;
  `}
`

export default ButtonNavigationStyled
