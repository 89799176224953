import styled from 'styled-components'

const VehicleShowcaseFrameStyled = styled.div`
  position: relative;
  overflow: hidden;
`

const VehicleShowcaseFramePlaceholderStyled = styled.div`
  height: 0;
  padding-bottom: 56.2%;
  z-index: 0;
`

const VehicleShowcaseFrameContentStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export {
  VehicleShowcaseFrameStyled,
  VehicleShowcaseFramePlaceholderStyled,
  VehicleShowcaseFrameContentStyled,
}
