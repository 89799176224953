import React from 'react'
import styled, { css } from 'styled-components'

const width = '63'
const height = '63'
const viewBox = '0 0 63 63'

const getDimensions = () => ({
  height,
  width,
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
`

const defaultProps = {
  children: [
    <path
      d="M19.944 55.946C11.51 51.975 5.477 43.774 4.549 34.079H.68c1.315 15.884 14.595 28.365 30.815 28.365l1.702-.077-9.825-9.825-3.43 3.404zM31.496.556l-1.702.077 9.825 9.825 3.43-3.43C51.48 11.025 57.514 19.2 58.416 28.896h3.868C60.996 13.036 47.715.556 31.496.556z"
      opacity="0.3"
      key="key-spin-active-arrows"
    />,
  ],
  viewBox,
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'IconSpinActivateArrows',
})
