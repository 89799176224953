import React from 'react'
import uuid from 'uuid/v4'
import _isString from 'lodash/isString'
import { ThemeProvider } from 'styled-components'

import theme from '../theme'
import Layout from '../global/Layout'
import { MsgStyled } from '../common/common'

import VehicleShowcaseFrame from '../components/VehicleShowcaseFrame'

const tryGetEventDataAsObject = eventData => {
  try {
    if (typeof eventData === 'object' && !Array.isArray(eventData)) {
      return eventData
    }

    const parsed = JSON.parse(eventData)

    if (typeof parsed !== 'object' || Array.isArray(parsed)) {
      return {}
    }

    return parsed
  } catch (_) {
    return {}
  }
}

class VehicleShowcaseIFrame extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      playerData: null,
    }

    this.onMessageReceived = this.onMessageReceived.bind(this)
  }

  onMessageReceived(event) {
    const { data } = event
    const {
      sliderImages,
      interiorImage,
      assets360,
      source,
    } = tryGetEventDataAsObject(data)
    const evaluatedAssets360 = Array.isArray(assets360) ? assets360 : []

    if (source !== 'vehicle-iframe-init') return

    this.setState({
      playerData: {
        sliderImages: Array.isArray(sliderImages) ? sliderImages : [],
        assets360: evaluatedAssets360,
        poster: evaluatedAssets360[0],
        interiorImage: _isString(interiorImage) ? interiorImage : '',
      },
    })

    this.initializationId = uuid()
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessageReceived, false)

    window.parent.postMessage('player-loaded', '*')
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessageReceived)
  }

  render() {
    const { playerData } = this.state

    return (
      <ThemeProvider theme={theme}>
        <Layout>
          {
            playerData
              ? (
                <VehicleShowcaseFrame
                  {...playerData}
                  key={this.initializationId}
                />
              )
              : (
                <MsgStyled>
                  No images to display, check your settings...
                </MsgStyled>
              )
          }
        </Layout>
      </ThemeProvider>
    )
  }
}

export default VehicleShowcaseIFrame
