import React from 'react'
import styled, { css } from 'styled-components'

const width = '25'
const height = '25'
const viewBox = '0 0 25 25'

const getDimensions = () => ({
  height,
  width,
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({ noStyles }) => (!noStyles ? getDimensionsCss() : null)}
`

const defaultProps = {
  children: [
    <path
      d="M24.6 13.8H5.9l8.6 8.6-2.2 2.2L0 12.3 12.3 0l2.2 2.2-8.6 8.6h18.7v3z"
      key="key-arrow-left"
    />,
  ],
  viewBox,
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'ArrowLeft',
})
