import React from 'react'
import PropTypes from 'prop-types'
import { tryGetImage } from './utils'

const defaultErrorTemplate = 'Image failed to load'

class ImageFallback extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      imageLoadFailed: false,
      isLoading: true,
    }

    this.hasUnmounted = false

    this.onLoadFailed = this.onLoadFailed.bind(this)
    this.onLoadSuccess = this.onLoadSuccess.bind(this)
    this.checkImage = this.checkImage.bind(this)
  }

  onLoadFailed() {
    if (this.hasUnmounted) {
      return
    }

    this.setState({
      imageLoadFailed: true,
      isLoading: false,
    })
  }

  onLoadSuccess() {
    if (this.hasUnmounted) {
      return
    }

    this.setState({
      imageLoadFailed: false,
      isLoading: false,
    })
  }

  checkImage(src) {
    tryGetImage(src)
      .then(this.onLoadSuccess, this.onLoadFailed)
  }

  componentDidMount() {
    this.hasUnmounted = false
    const {
      src,
    } = this.props

    if (!src) {
      this.setState({ isLoading: false })
    } else {
      this.checkImage(src)
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { src } = this.props

    if (src !== nextProps.src) {
      this.checkImage(nextProps.src)
    }
  }

  componentWillUnmount() {
    this.hasUnmounted = true
  }

  render() {
    const {
      children,
      src,
      imageComponent: ImageComponent,
      ...props
    } = this.props

    const {
      imageLoadFailed,
      isLoading,
    } = this.state

    return isLoading || imageLoadFailed || !src
      ? children
      : (
        // eslint-disable-next-line jsx-a11y/alt-text
        <ImageComponent {...props} {...{ src }} />
      )
  }
}

ImageFallback.defaultProps = {
  children: defaultErrorTemplate,
  imageComponent: 'img',
}

ImageFallback.propTypes = {
  children: PropTypes.node,
  imageComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
}

export default ImageFallback
