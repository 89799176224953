import styled, { keyframes, css } from 'styled-components'
import { rgba } from 'polished'

import IconSpinActivateArrows from '../Icons/SpinActivateArrows'
import LoadingSpinner from '../Icons/LoadingSpinner'
import { icon } from '../../common/Mixins'
import media from '../../common/MediaQueries'

const rotate360 = keyframes`
  from {
    transform: rotate(360deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(0deg) translate(-50%, -50%);
  }
`

const RoundelStyled = styled.a`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${({ theme }) => theme.sizes.roundelSizePhone};
  width: ${({ theme }) => theme.sizes.roundelSizePhone};
  text-align: center;
  color: ${({ theme }) => theme.colors.cobalt};
  z-index: 15;
  opacity: 1;
  cursor: ${props => (props.isLoading ? 'default' : 'pointer')};
  transition:
    transform 0.45s ${({ theme }) => theme.ease.ease_in_out_circ} 0.5s,
    opacity 1.5s ${({ theme }) => theme.ease.ease_out_circ},
    left 0.1s linear 1s;
  
  ${({ isClickable }) => !isClickable && css`
    pointer-events: none;
  `}

  &.remove {
    transform: translate(-50%, -50%) scale(0.4);
    opacity: 0;
    left: -6000px;

    .arrow {
      transition: transform 0.38s ${({ theme }) => theme.ease.ease_out_circ}
        0.5s;
      transform: scale(0.3) rotate(-360deg) translate3d(-50%, -50%, 0);
    }

    .spinner {
      transform: rotate(-90deg) translate3d(-50%, -50%, 0);
    }
  }

  .spinner {
    transition:
      transform 0.3s ${({ theme }) => theme.ease.ease_in_out_circ} 0.35s;
    transform: rotate(90deg) translate3d(-50%, -50%, 0);
    opacity: 0;
  }

  &.loading .spinner {
    opacity: 1;
    transform: rotate(0deg) translate3d(-50%, -50%, 0);
  }

  .content {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    font-size: 10px;
    opacity: 1;
  }

  &.loading .content {
    opacity: 0;
  }

  .arrow {
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: scale(1) rotate(200deg) translate3d(-50%, -50%, 0);
  }

  &.loading .arrow {
    opacity: 0;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    height: ${({ theme }) => theme.sizes.roundelShadowSizePhone};
    width: ${({ theme }) => theme.sizes.roundelShadowSizePhone};
    background: ${({ theme }) => rgba(theme.colors.white, 0.3)};
    opacity: 1;
    border-radius: 50%;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0 0;
    transition: transform 0.45s ${({ theme }) => theme.ease.ease_in_out_circ};
  }

  &.loading::before {
    transform: translate(-50%, -50%);
  }

  &.remove::before {
    transition: transform 0.4s ${({ theme }) => theme.ease.ease_in_back} 0.5s;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: ${({ theme }) => theme.sizes.roundelCenterSizePhone};
    width: ${({ theme }) => theme.sizes.roundelCenterSizePhone};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
    z-index: 0;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0 0;
    transition: transform 0.25s ${({ theme }) => theme.ease.ease_in_out_circ}
      0.03s;
  }

  &.loading::after {
    transform: translate(-50%, -50%);
  }

  @media (${media.phone}) {
    height: ${({ theme }) => theme.sizes.roundelSize};
    width: ${({ theme }) => theme.sizes.roundelSize};

    .content {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 17px;
    }

    &::before {
      height: ${({ theme }) => theme.sizes.roundelShadowSize};
      width: ${({ theme }) => theme.sizes.roundelShadowSize};
    }

    &::after {
      height: ${({ theme }) => theme.sizes.roundelCenterSize};
      width: ${({ theme }) => theme.sizes.roundelCenterSize};
    }
  }
`

const CenterStyled = styled.div`
  & > span {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ActivateArrowsStyled = styled(IconSpinActivateArrows)`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  animation: ${rotate360} 30s linear infinite;
  color: ${({ theme }) => theme.colors.cobalt};
  font-size: 35px;
  ${icon}

  @media (${media.phone}) {
    font-size: 63px;
  }
`

const SpinnerStyled = styled(LoadingSpinner)`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  animation: ${rotate360} 30s linear infinite;
  transform-origin: 0 0;
  transform: translate3d(-50%, -50%, 0);
  color: ${({ theme }) => theme.colors.cobalt};
  font-size: 35px;
  ${icon}

  @media (${media.phone}) {
    font-size: 48px;
  }
`

export {
  RoundelStyled,
  CenterStyled,
  ActivateArrowsStyled,
  SpinnerStyled,
}
