/* global removepano, embedpano */
import React from 'react'
import Script from 'react-load-script'
import { string, func, node } from 'prop-types'
import _noop from 'lodash/noop'

import PanoramaStyled from './styles'

const ID_PANORAMA = 'kr-panorama'

class VehicleShowcasePanorama extends React.Component {
  constructor(props) {
    super(props)

    this._handleScriptLoad = this._handleScriptLoad.bind(this)
    this._onReady = this._onReady.bind(this)
    this.embed = this.embed.bind(this)
    this.remove = this.remove.bind(this)

    this.plugin = null
    this.loadingClass = 'loading'
  }

  shouldComponentUpdate() {
    return false
  }

  // MARK: ASYNC Script loading
  _handleScriptLoad() {
    this.embed()
  }

  _onReady(_interface) {
    const {
      onReadyCallback,
    } = this.props

    this.plugin = _interface

    // as we've set the component not to update we can change directly
    // fake loading as we don't have an loaded pano event
    document.getElementById(ID_PANORAMA).classList.remove(this.loadingClass)
    onReadyCallback()
  }

  // MARK: API
  remove() {
    removepano(ID_PANORAMA)
  }

  get panoSettings() {
    if (typeof window === 'undefined') {
      return {}
    }

    const isWebkit = 'WebkitAppearance' in document.documentElement.style
    const isFirefox = !(window.mozInnerScreenX == null)

    return {
      'cursors.standard': isWebkit
        ? '-webkit-grab'
        : isFirefox
          ? '-moz-grab'
          : 'grab',
      'cursors.dragging': isWebkit
        ? '-webkit-grabbing'
        : isFirefox
          ? '-moz-grabbing'
          : 'grabbing',
    }
  }

  embed() {
    const { image } = this.props

    embedpano({
      swf: '/vendor/krpano/krpano.swf',
      xml: '/vendor/krpano/krpano.xml',
      target: ID_PANORAMA,
      initvars: { pathtopano: image },
      onready: this._onReady,
      vars: this.panoSettings,
    })
  }

  render() {
    const {
      poster,
      children,
    } = this.props

    return (
      <PanoramaStyled
        width={12 / 12}
        bg={poster}
        id={ID_PANORAMA}
        className={this.loadingClass}
      >
        <Script
          url="/vendor/krpano/krpano.js"
          onCreate={this._handleScriptCreate}
          onError={this._handleScriptError}
          onLoad={this._handleScriptLoad}
        />
        {children}
      </PanoramaStyled>
    )
  }
}

VehicleShowcasePanorama.defaultProps = {
  poster: '/temp/temp-interior-360-image',
  image: '/temp/temp-showcase-internal-360.jpg',
  onReadyCallback: _noop,
  children: undefined,
}

VehicleShowcasePanorama.propTypes = {
  poster: string,
  image: string,
  onReadyCallback: func,
  children: node,
}

export default VehicleShowcasePanorama
