import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
import PropTypes from 'prop-types'
import _noop from 'lodash/noop'

import {
  ImageGallerySliderStyled,
  ImageGalleryMediaStyled,
  ImageGalleryMediaWrapperStyled,
} from './styles'
import Navigation from '../Navigation'
import ImageFallback from '../../common/ImageFallback'

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)

class ImageGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      index: props.initialIndex || 0,
      screen: {
        height: 1,
        width: 1,
      },
    }

    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this)
    this.handleChangeIndex = this.handleChangeIndex.bind(this)
    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  componentDidMount() {
    this.setState({
      screen: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    })
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize() {
    this.setState({
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    })
  }

  handleChangeIndex(index) {
    const { onImageChange } = this.props

    this.setState({
      index,
    })

    onImageChange && onImageChange(index)
  }

  next(e) {
    const { index } = this.state

    if (e) e.preventDefault()

    this.handleChangeIndex(index + 1)
  }

  prev(e) {
    const { index } = this.state
    const { onPrevClick } = this.props

    if (e) e.preventDefault()

    onPrevClick(index)

    if (index !== 0) {
      this.handleChangeIndex(index - 1)
    }
  }

  render() {
    const {
      images,
      slidesLength,
      slideNumberDisplayOffset,
    } = this.props
    const { index, screen } = this.state
    const imagePreviews = images.map(image => (
      <ImageFallback
        windowRatio={screen.height / screen.width}
        src={image}
        key={image}
        imageComponent={ImageGalleryMediaStyled}
      />
    ))

    return (
      <ImageGallerySliderStyled>
        <ImageGalleryMediaWrapperStyled>
          <BindKeyboardSwipeableViews
            index={index}
            onChangeIndex={this.handleChangeIndex}
            enableMouseEvents
          >
            {imagePreviews}
          </BindKeyboardSwipeableViews>
        </ImageGalleryMediaWrapperStyled>
        <Navigation
          slidesLength={slidesLength}
          activeIndex={index + slideNumberDisplayOffset}
          onLeftClick={this.prev}
          onRightClick={this.next}
        />
      </ImageGallerySliderStyled>
    )
  }
}

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  initialIndex: PropTypes.number,
  slidesLength: PropTypes.number,
  onImageChange: PropTypes.func,
  onPrevClick: PropTypes.func,
  slideNumberDisplayOffset: PropTypes.number.isRequired,
}

ImageGallery.defaultProps = {
  images: [],
  initialIndex: 0,
  onImageChange: _noop,
  onPrevClick: _noop,
  slidesLength: 0,
}

export default ImageGallery
